.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navigation Bar */
nav {
  background-color: #f0f0ea;
  color: hsl(0, 0%, 33%);
  font-weight: 600;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure the nav bar stays on top of other content */
}

.nav-left, .nav-right {
  display: flex;
}

.nav-left div, .nav-right div, .nav-right button {
  color: #535353;
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
}

.nav-right button {
  background-color: #60aaf2;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  margin-left: auto; /* Ensures the logout button aligns to the right */
}

.nav-left div:hover, .nav-right div:hover, .nav-right button:hover {
  color: #60aaf2;
}

/* General Top Banner Styles */
.top-banner {
  background-color: #60aaf2; /* A general success green background */
  color: white;
  padding: 15px 20px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

/* Specific Style for Password Changed Banner */
.password-changed-banner {
  background-color: #7be5ac; /* Specific background color for password changed */
}

.banner-message {
  flex-grow: 1;
  text-align: center;
}

.close-banner {
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: none;
  position: absolute;
  right: 20px;
  top: 10px;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #535353;
}

/* Responsive Design */
@media (max-width: 580px) {
  .nav-left {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #60aaf2;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999; /* Below hamburger but above other content */
  }

  .nav-left.open {
    transform: translateX(0);
  }

  .nav-left div, .nav-left div:hover {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
    width: 100%;
    text-align: left;
  }

  .hamburger {
    display: flex;
    z-index: 1000; /* Ensures hamburger is always clickable */
  }

  .nav-right {
    width: 100%;
    justify-content: flex-end;
  }

  .logout-btn {
    display: none; /* Hide logout button inside hamburger menu */
  }
}


/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f0f0ea;
  color: #333;
}

a {
  color: #007bff;
  text-decoration: none;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #FF69B4; /* Bright pink color */
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px; /* Pill shape */
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none; /* Removes underline from links */
  outline: none; /* Removes the outline */
}

/* Hover and focus styles for buttons */
.btn:hover, .btn:focus {
  background-color: #ff85c8; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
}

/* Buttons */
.button, button {
  background-color: #535353;
  color: #f0f0ea;
  border: none;
  padding: 10px 20px;
  margin: 10px 5px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover, button:hover {
  opacity: 0.8;
}

/* Forms */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px 0;
  display: block;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Utility Classes */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.center {
  text-align: center;
}

/*Home Page*/
.homepage-button-container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.homepage-login-btn {
  display: inline-block;
  padding: 10px 30px;
  margin: 10px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none; /* Removes underline from links */
  outline: none; /* Removes the outline */
  border-radius: 25px;
  margin-top: 35px;
  background-color: #60aaf2;
}

.homepage-login-btn:hover, .homepage-login-btn:focus {
  background-color: #4b8ccc; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}

.homepage-enter-code-btn {
  display: inline-block;
  padding: 10px 30px;
  margin: 10px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none; /* Removes underline from links */
  outline: none; /* Removes the outline */
  border-radius: 25px;
  margin-top: 10px;
  background-color: #FF6F61;
}

.homepage-enter-code-btn:hover, .homepage-enter-code-btn:focus {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}


/*User Profile Settings and Editing Form and Page*/
.profile-form-container {
  max-width: 80vw; /* Maximum width as a percentage of viewport width */
  margin: 20px auto; /* Centers the form */
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: #fff;
}

.settings-header {
  text-align: center;
  margin-bottom: 30px;
}

/* Profile Form */
.profile-form {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  margin-left: 15px;
  margin-bottom: 20px;
  max-width: 90%;
}

.profile-form-label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.profile-form-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  max-width: 80%;
}

.profile-form-input:focus {
  outline: none;
  border-color: #007BFF;
}

/* Button container to wrap buttons */
.profile-form-button-container {
  display: flex;
  justify-content: space-between; /* Space out buttons on larger screens */
  gap: 10px; /* Optional: adds some space between buttons */
  margin-right: 0px;
}

.profile-form-button {
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  font-weight: bold;
  padding: 0px;
  border-radius: 5px;
  border: none;
}

.profile-form-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.edit-mode-toggle-btn {
  margin-top: 10px;
  background-color: #28a745;
}

.profile-form-button, .edit-mode-toggle-btn {
  flex-grow: 1; /* Allows buttons to grow and fill available space */
  padding: 10px; /* Adjust padding as needed */
  margin-right: 0px;
  /* Other button styles */
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column; /* Stack buttons vertically on smaller screens */
  }
}

/* Additional styles for ChangePassword Link and ProfilePictureUpload components */
.upload-picture-header {
  margin-top: 60px;
  margin-bottom: 10px;
}

.profile-picture-upload {
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
}

.upload-picture-btn {
  border-radius: 25px;
  margin-top: 35px;
  background-color: #708090;
}

.change-password-link {
  display: block; /* Makes the link occupy the full width of its container */
  color: #969696; /* Primary color for the link */
  padding: 10px 0; /* Padding on top and bottom for larger click area */
  margin: 30px 0; /* Margin to separate it from other content */
  text-decoration: none; /* Removes underline */
  text-align: center; /* Centers the link text horizontally */
  transition: color 0.3s; /* Smooth color transition on hover */
  font-weight: 600;
}

.change-password-link:hover {
  color: #4f4f4f; /* Darker shade on hover for feedback */
}

/*User Profile Page*/
.user-profile-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
}

.profile-header {
  display: flex;
  flex-direction: row; /* Adjusted to row */
  align-items: center;
  width: 100%;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px; /* Adjusted margin to right */
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-name {
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
  margin-bottom: 10px;
  color: #333;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-position, .user-company {
  font-size: 16px;
  font-weight: 400; /* Heavier font weight for job and company */
  color: #333;
  margin: 3px 20px;
}

.user-profile-page {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.profile-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .profile-content {
    flex-direction: column;
    align-items: center;
  }

  .profile-header {
    flex-direction: column; /* Stack photo and name vertically on smaller screens */
    align-items: center;
  }

  .profile-picture {
    margin-right: 0; /* Remove margin right on smaller screens */
    margin-bottom: 20px; /* Add bottom margin back for vertical stacking */
  }
}

.btn.edit-profile-btn {
  background-color: #FF6F61; /* Bright Pink */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 999px; /* Pill shape */
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 25px;
}

.btn.edit-profile-btn:hover, .btn.edit-profile-btn:focus {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}

/* Inviters Display Styles */
.inviters-display {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 600px; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.section-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 10px; /* Creates space between title and details */
  text-align: left;
}

.inviter-details-container {
  display: flex;
  width: 100%;
}

.invitation-details-titles {
  font-weight: 600;
}

.inviter-detail {
  flex: 1;
  margin-right: 30px; /* Creates space between the two inviter details */
  text-align: left;
}

.inviter {
  display: flex;
  align-items: center;
  margin: 15px 20px; /* Space between each inviter */
}

.inviter-profile-picture {
  width: 40px; /* Small circle */
  height: 40px;
  border-radius: 50%; /* Circle shape */
  object-fit: cover;
  margin-right: 10px; /* Space between picture and name */
}

/* Adjustments for smaller screens */
@media (max-width: 700px) {
  .inviter-details-container {
    flex-direction: column;
  }

  .inviter-detail {
    margin-right: 0;
    margin-bottom: 8px; /* Adds space between inviter details when stacked */
  }
}

.no-inviter-message {
  color: #999;
  font-style: italic;
}

/*Change Password Page*/

.change-password-header {
  text-align: center; /* Centers the header text */
  color: #333; /* Dark color for the text for readability */
  margin-bottom: 30px; /* Adds space below the header */
}

@media (max-width: 768px) {
  .change-password-container {
    margin: 20px; /* Adjusts margin for smaller screens */
    padding: 15px; /* Adjusts padding for smaller screens */
  }
}

/*Change Password Form*/
.change-password-form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  align-items: flex-end; /* Aligns children (form groups) to the end (right) */
}

.change-password-form div {
  width: 100%; /* Ensures div takes full width of form */
  display: flex;
  justify-content: space-between; /* Spreads label and input */
  margin-bottom: 15px;
}

.change-password-form label {
  margin-top: 7px; /* Adjusted if you decide to align label above input */
  font-weight: bold;
  /* Aligns text to the right; remove if labels are above inputs */
  align-self: flex-start; /* Aligns label to the start (left), counters the form's flex-end alignment */
  width: 250px;
}

.change-password-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* Width adjustment might be needed depending on design preference */
  width: 60%; /* Adjust based on your preference */
}

.change-password-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: center; /* Centers button in the form */
}

.change-password-form button:hover {
  background-color: #0056b3;
}

/*Reauthentication Form*/
/* Container for the entire form */
.reauthentication-container {
  max-width: 400px; /* Use max-width for responsiveness */
  width: 90%; /* Adjust width to be responsive */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f0f0ea;
  box-sizing: border-box; /* Ensure padding does not add to the width */
}

.reauthentication-header {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.form-label {
  flex: 1;
  margin-right: 10px;
}

.form-input {
  flex: 2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

.form-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-error {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}

@media (max-width: 480px) {
  .reauthentication-container {
    width: 95%; /* Increase width for very small screens */
  }
}

/*Login and Register Pages and Components*/
/* Form General Styles */
.form-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  border: 2px;
  border-color: #535353;
  background-color: #f0f0ea;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.form-error {
  color: #d9534f;
  margin-top: 10px;
  text-align: center;
}

.btn, .form-submit-btn {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out;
}

.btn:hover, .form-submit-btn:hover {
  background-color: #0056b3;
}

/* Page Specific Styles */
.login-page, .signup-page {
  text-align: center;
}

/* Adjustments for Responsive Design */
@media (max-width: 768px) {
  .form-container {
      width: 90%;
  }
}

/*Redirect Link Text at Login/Sign up Page Bottom*/
.redirect-link-text {
  padding: 25px;
}

/*Enter Invitations Page*/
.enter-invitation-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.enter-invitation-header {
  margin-bottom: 50px;
}

.enter-invitation-paragraph {
  margin-bottom: 40px;
}

.login-link {
  font-weight: bold; /* Makes the text bold */
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
  text-decoration: none; /* Removes the underline by default */
  color: #60aaf2;
}

.login-link:hover {
  color: #4688ca;
  text-decoration: underline; /* Adds underline on hover */
}

.enter-invitation-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center; /* Align items vertically in the center */
  flex-wrap: wrap; /* Allows form elements to wrap on smaller screens */
}

.invitation-code-input, .submit-invitation-button {
  box-sizing: border-box; /* Makes sure padding doesn't affect overall height */
  font-size: 16px; /* Adjust size as needed */
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 9999px; /* Pill shape */
  border: 1px solid #ccc;
  height: 48px; /* Ensure matching heights */
  flex-grow: 1; /* Input grows to fill available space */
}

.invitation-code-input {
  min-width: 250px; /* Prevents input from becoming too small */
}

.submit-invitation-button {
  background-color: #FF6F61;
  color: white;
  border: none;
  cursor: pointer;
  min-width: 150px; /* Ensures button has a readable size */
  line-height: normal; /* Reset line height */
  align-self: stretch; /* Forces button to fill the height */
}

.submit-invitation-button:hover {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}

.invitation-message {
  color: red;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .enter-invitation-form {
      flex-direction: column; /* Stack elements vertically */
  }

  .invitation-code-input, .submit-invitation-button {
      width: 100%; /* Full width for small screens */
  }
}

/*User Registration Page*/
/* Container for the entire user registration section */
.user-registration-container {
  max-width: 80vw;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f0f0ea;
}

/* Header style for the user registration */
.user-registration-header {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

/* Form style for user registration */
.user-registration-form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Spacing between form elements */
}

/* Input fields style */
.user-registration-input {
  padding: 10px;
  border-radius: 25px; /* Pill-shaped inputs */
  border: 1px solid #ccc;
  background-color: white;
  font-size: 14px; /* Slightly larger text */
}

/* Submit button style */
.user-registration-submit {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 25px; /* Pill shape for consistency with inputs */
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px; /* Additional spacing above the submit button */
}

.user-registration-submit:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

/* Message display style */
.user-registration-message {
  margin-top: 20px;
  color: #333;
  text-align: center;
}

/* Mini Dashboard Page */
.mini-dashboard-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f0f0ea;
}

.mini-dashboard-header {
  color: #333;
  margin-bottom: 30px;
  text-align: center;
}

.mini-dashboard-btn {
  background-color: #60aaf2;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 25px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

.mini-dashboard-btn:hover {
  background-color: #007bff;
}

.second-code-form-header {
  margin-top: 75px;
  margin-bottom: 30px;
}

.mini-dashboard-label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}

/* MiniDashboard Form Styles */
.mini-dashboard-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center; /* Align items vertically in the center */
  flex-wrap: wrap; /* Allows form elements to wrap on smaller screens */
}

.mini-dashboard-input, .mini-dashboard-submit {
  box-sizing: border-box; /* Makes sure padding doesn't affect overall height */
  font-size: 16px; /* Adjust size as needed */
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 9999px; /* Pill shape for consistency */
  border: 1px solid #ccc;
  height: 48px; /* Ensure matching heights with the button */
  flex-grow: 1; /* Input grows to fill available space */
}

.mini-dashboard-input {
  min-width: 250px; /* Prevents input from becoming too small */
}

.mini-dashboard-submit {
  background-color: #FF6F61; /* Consistent button color */
  color: white;
  border: none;
  cursor: pointer;
  min-width: 150px; /* Ensures button has a readable size */
  line-height: normal; /* Reset line height */
  align-self: stretch; /* Forces button to fill the height */
}

.mini-dashboard-submit:hover {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;}

.mini-dashboard-message {
  color: red; /* Consistent message color for errors or success */
}

@media (max-width: 768px) {
  .mini-dashboard-container {
    width: 90%;
    padding: 15px;
  }
}

/*Dashboard Page and Content Styles*/
.manage-invitations-btn {
  border-radius: 25px;
  margin-top: 35px;
  background-color: #FF6F61;
}

.manage-invitations-btn:hover, .manage-invitations-btn:focus {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}

/*Generate Invitations Form and Page*/
.create-invitation-code-header {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.generate-code-btn {
  border-radius: 25px;
  margin-top: 35px;
  background-color: #FF6F61;
}

.generate-code-btn:hover, .generate-code-btn:focus {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}

.plus-symbol {
  font-size: 24px;  /* Larger size */
  font-weight: bold; /* Bold style */
  vertical-align: middle; /* Center align vertically */
  line-height: 1; /* Adjust line height to align better */
}

.manage-invitations-link-in-create-invitations-page {
  display: block; /* Makes the link occupy the full width of its container */
  max-width: 300px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  color: #969696; /* Primary color for the link */
  text-decoration: none; /* Removes underline */
  text-align: center; /* Centers the link text horizontally */
  transition: color 0.3s; /* Smooth color transition on hover */
  font-weight: 600;
}

.generate-a-code-to-invite-top-talent-header {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.create-invitation-message-container {
  background-color:#282c34;
  margin-top: 30px;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 30px;
}

.create-invitation-message {
  line-height: 1.5;
  color: #ffffff;
}

.manage-invitations-link-in-create-invitations-page:hover {
  color: #4f4f4f; /* Darker shade on hover for feedback */
}

/*Forgot Password Page*/
/* ForgotPassword Component Styles */
.forgot-password-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.forgot-password-header {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button {
  width: 100%;
  padding: 10px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-error, .form-success {
  text-align: center;
  margin-top: 10px;
}

.forgot-password-link {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

/* Manage Invitations Page and Components */
.invitation-list {
  margin-bottom: 60px;
}

.invitation-item {
  width: 90%; /* Limits the maximum width */
  max-width: 650px;
  margin: auto; /* Centers the item */
  display: flex;
  flex-direction: column; /* Allows items to stack vertically */
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.invitation-item-header {
  display: flex; /* This keeps the header items in a row */
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

/* Adjustments for details button and revoke button for consistency */
.invitation-details-button, .revoke-invitation-button {
  padding: 8px 12px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  white-space: nowrap; /* Prevents text wrapping */
}

.revoke-invitation-button {
  background-color: #d75f6be5;
  color: white;
}

.revoke-invitation-button:hover {
  background-color: #e35252fa;
}

.revoke-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f37575;
  color: white;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.invitation-details-button {
  background-color: #007bff;
  color: white;
}

.invitation-details-button:hover {
  background-color: #0056b3;
}

.invitation-details {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.invitation-details-button-inactive {
  background-color: #949494; /* Grey color for the details button when inactive */
}

.invitation-details-button-inactive:hover {
  background-color: #d3d3d3; /* Keep the same color on hover to indicate inactivity */
}

.status-indicator {
  display: inline-block;
  padding: 5px 5px;
  border-radius: 15px;
  color: white;
  font-weight: bold;
  text-align: center;
  width: 100px; /* Adjust as needed */
}

.status-indicator.active {
  background-color: #b2e9b5; /* Green for active */
}

.status-indicator.revoked {
  background-color: #c6c6c6; /* Grey for revoked */
}

.status-indicator.used {
  background-color: #c6c6c6; /* Grey for used */
}

.status-indicator.expired {
  background-color: #c6c6c6; /* Grey for expired */
}

/*Profile Picture Upload*/
.upload-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #60aaf2;
  color: white;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

/*Sign Up with Google*/
.googleSignInImage {
  max-width: 200px;
  margin: auto;
}

/*User Registration Message*/
.user-registration-message {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #60aaf2;
    color: white;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

.faq-page-container {
  width: 90%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.faq-header {
  text-align: center;
  color: #333;
}

.faq-item {
  margin-top: 10px;
}

.faq-question {
  width: 100%;
  padding: 10px;
  background-color: #60aaf2;
  border: none;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background-color: #4688ca;
}

.faq-answer {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  line-height: 1.3;
}

.faq-contact-support {
  padding-top: 40px;
  padding-bottom: 50px;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.faq-support-link {
  font-weight: 600;
}

.faq-section {
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
}

.faq-section-title {
  color: #333;
  margin-bottom: 15px;
  text-align: left;
}

/* You might want to consider adding an icon or a slight style modification to indicate expandability of questions */
.faq-question::after {
  content: '+';
  float: right;
  font-size: 1.2em;
}

/* Adjust icon/content when question is open */
.faq-question[aria-expanded="true"]::after {
  content: '-';
}

/* New classes for section titles */
.faq-section-title {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc;
}

/*Floating Supoport Button*/
.floating-support-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #afafac;
  color: white;
  border: none;
  border-radius: 100px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px; /* Adjust the size as needed */
  height: 33px; /* Ensures a circle shape */
  text-decoration: none; /* Ensure text is not underlined */
  font-size: 15px; /* Ensure text size is appropriate */
  font-weight: 600;
  opacity: 0.91;
}

/*Activity Feed*/
.activity-feed {
  margin: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.activity-item:last-child {
  border-bottom: none;
}

/*Invitation Stats*/
.invitation-stats {
  margin: 15px;
  padding: 20px; /* Reduced padding for smaller screens */
  background-color: #f0f0ea; /* Match this with your app background if needed */
  border-radius: 8px;
  width: 90%; /* Set width to 90% to increase size on smaller screens */
  max-width: 600px; /* Set a max-width for larger screens */
  margin-left: auto; /* Centering the box */
  margin-right: auto; /* Centering the box */
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensures spacing is maintained */
}

.stat-item {
  padding: 10px;
  margin: 5px; /* Reduced margin to fit better on smaller screens */
  font-weight: bold;
  color: #333;
  border-radius: 8px; /* Rounded corners for each stat box */
  flex: 1 1 45%; /* Adjusted to maintain two items per row */
  text-align: center;
  min-width: 140px; /* Reduced min-width to accommodate smaller screens */
  box-sizing: border-box; /* Ensures padding is included in width calculation */
  font-size: 15px; /* Decreased font size by 2px */
}

/* Special class for the total code count to make it span full width */
.stat-item.total {
  flex-basis: 100%;
  background-color: #f0f0ea;
  border-style: solid;
  border-width: 1px;
}

.stat-item.active {
  background-color: #d4f2d5; /* Light green background for active */
}

.stat-item.revoked {
  background-color: #ffe0e0; /* Light red background for revoked */
}

.stat-item.expired {
  background-color: #dfdfdf; /* Grey background for expired */
}

.stat-item.used {
  background-color: #ffffff; /* White background for used */
}

/*Welcome Page for New Full Users*/
.welcome-container {
  text-align: center;
  margin-bottom: 50px;
}

.enter-app-btn {
  background-color: #FF6F61;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 2px;
  cursor: pointer;
  border-radius: 25px;
  transition: 0.2s;
}

.enter-app-btn:hover, .enter-app-btn:focus {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}

.welcome-new-user-message-container{
  background-color: #ffffff;
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  line-height: 1.4;
}

.welcome-new-user-message {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 700px;
}

/*Onboarding Slideshow Full user component*/
.slideshow-container {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  position: relative;
  width: 80%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.slide {
  padding: 20px;
  margin-bottom: 10px;
  min-height: 330px;
  position: relative; /* Set relative to position the buttons */
}

.slide img {
  width: 100%;
  height: auto;
}

.slide-btn {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  background-color: transparent; /* To let the slide image be visible */
  border: none;
  color: white; /* Sets emoji color */
  cursor: pointer;
  font-size: 32px; /* Adjust size of emojis */
  z-index: 2; /* Ensures button is above the image */
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5); /* Adds a shadow to the emoji for better visibility */
  user-select: none; /* Prevents text selection of the emoji */
}

.previous-btn {
  left: 10px; /* Place it on the left side */
}

.next-btn {
  right: 10px; /* Place it on the right side */
}

/* Media query for screens less than 480px */
@media (max-width: 520px) {
  .slideshow-container {
    width: 90%; /* Adjust width to 90% of the screen */
  }

  .slide-btn {
    font-size: 24px; /* Smaller emojis on smaller screens */
  }
}


/*Welcome Page for New Partial Users*/
.welcome-container-partial {
  text-align: center;
  margin-bottom: 50px;
}

.enter-app-btn-partial {
  background-color: #FF6F61; /* Consistent color scheme as full users */
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 2px;
  cursor: pointer;
  border-radius: 25px;
  transition: 0.2s;
}

.enter-app-btn-partial:hover, .enter-app-btn-partial:focus {
  background-color: #FF6F61; /* Slightly lighter pink on hover/focus */
  transform: translateY(-2px); /* Slight lift effect */
  text-decoration: none; /* Ensures no underline on hover for link-styled buttons */
  opacity: 0.8;
}

.welcome-new-user-message-partial {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 700px;
}

/*Onboarding Slideshow Partial user component*/
.slideshow-container-partial {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  position: relative;
  width: 80%; /* Default width */
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.slide-partial {
  padding: 20px;
  margin-bottom: 10px;
  min-height: 330px;
  position: relative; /* Set relative to position the buttons */
}

.slide-partial img {
  width: 100%;
  height: auto;
}

.slide-btn-partial {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  background-color: transparent; /* To let the slide image be visible */
  border: none;
  color: white; /* Sets emoji color */
  cursor: pointer;
  font-size: 32px; /* Adjust size of emojis */
  z-index: 2; /* Ensures button is above the image */
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5); /* Adds a shadow to the emoji for better visibility */
  user-select: none; /* Prevents text selection of the emoji */
}

.slide-btn-partial.previous {
  left: 10px; /* Place it on the left side */
}

.slide-btn-partial.next {
  right: 10px; /* Place it on the right side */
}

/* Media query for screens less than 480px */
@media (max-width: 520px) {
  .slideshow-container-partial {
    width: 90%; /* Adjust width to 90% of the screen */
  }

  .slide-btn-partial {
    font-size: 24px; /* Smaller emojis on smaller screens */
  }
}


/*Team Message Component*/
.team-message-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  border-radius: 8px;
}

.team-message-header {
  color: #333;
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
}

.team-message-container p {
  line-height: 1.5;
  color: #363636;
  text-align: justify;
  margin-bottom: 10px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  max-width: 700px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

/*Feature Section*/
.image-text-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  gap: 20px;
}

.section-image {
  flex: 1;
  max-width: 50%;
}

.section-image img {
  width: 100%;
  height: auto;
  max-width: 600px;
  object-fit: contain;
}

.section-text {
  flex: 1;
  max-width: 50%;
  padding: 0 20px;
}

.section-text h2 {
  font-size: 1.5em; /* Adjust size as needed */
  margin-bottom: 0.5em;
}

.section-text p {
  font-size: 1em; /* Adjust size as needed */
  line-height: 1.6;
}

/* Responsive design */
@media (max-width: 768px) {
  .image-text-section {
    flex-direction: column;
  }

  .section-image, .section-text {
    max-width: 100%;
  }

  .section-text h2 {
    font-size: 1.2em;
  }

  .section-text p {
    font-size: 0.9em;
  }
}
